import { apiHelper } from '@/utils'
import { API } from '@/constants'

export const userService = {
    getAllUsers, 
    updateUserStatus,
    getUser,
    updateUser,
    unlockUser,
    createUser,
    updateProfile,
  };

const MODULE = {
    USER: 'users',
}

// const API_URL = API.BASE_URL.replace('@serviceName', 'user') + '/v1'
const API_URL = API.BASE_URL + '/v1'


export function getAllUsers(filter) {

    const requestOptions = {
      params: {
        page: filter.pageNumber,
        size: filter.pageSize,
        is_locked: filter.isLocked,
        from: 'MANDRILL',
        is_active: filter.isActive
      }
    }

    let array = filter.searchArray
    array.forEach((word, index) => {
      if(word != null || word != '')
      {
        var searchParam = `search_words[${index}]`
        requestOptions.params[searchParam] = word
      }
    })
    
    return apiHelper(API_URL).get(`/${MODULE.USER}`, requestOptions)
}


export function updateUserStatus(data) {
  const requestOptions = {
    is_active: data.isActive
  }

  return apiHelper(API_URL).patch(`/${MODULE.USER}/${data.id}/status`, requestOptions)
}

export function getUser(id) {
  return apiHelper(API_URL).get(`/${MODULE.USER}/${id}`)
}

export function updateUser(data) {
  const requestOptions = {
    name: data.name,
    email: data.email,
    is_active: data.isActive,
  }

  return apiHelper(API_URL).put(`/${MODULE.USER}/${data.id}/external`, requestOptions)
}

export function unlockUser(id) {
  return apiHelper(API_URL).patch(`/${MODULE.USER}/${id}/unlock`)
}

export function createUser(data) {
  const requestOptions = {
    name: data.name,
    email: data.email,
  }
  return apiHelper(API_URL).post(`/${MODULE.USER}/external`, requestOptions)
}

export function updateProfile(data) {
  const requestOptions = {
    name: data.name,
    email: data.email,
    role_uuid : data.roleId
  }

  if(data.password !="" && data.password != null)
  {
    requestOptions.password = data.password; 
  }


  return apiHelper(API_URL).put(`/${MODULE.USER}/profile`, requestOptions)
}