import { meetingRoomBookingService } from '../../helpers/services/meetingRoomBooking.service';
import i18n from '@/i18n'
import { sharedHelper } from '@/utils'
import moment from 'moment';

export const state = {
  list: {
    data: [],
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      lastPage: 0,
      tableRowsCount: 0
    }
  },
  details: {},
  status: {},
  abcd: [
    {
      id: 1,
      title: 'All Day Event',
      start: new Date().setDate(new Date().getDate() + 2),
      className: 'bg-warning text-white',
    },
  ]
}

export const MEETING_ROOM_BOOKING_MODULE = "meetingRoomBooking";

// define action 
export const GET_ALL_BOOKINGS = 'getAllBooking';
export const GET_BOOKING = 'getBooking';
export const UPDATE_BOOKING = 'updateBooking';
export const CREATE_BOOKING = 'createBooking';
export const DELETE_BOOKING = 'deleteBooking';

// define mutation
const REQUESTING = "requesting";
const ALL_BOOKING_SUCCESS = "allBookingSuccess";
const SUCCESS = "commonSuccess";
const FAILURE = "commonFailure";
const GET_BOOKING_SUCCESS = "getBookingSuccess";

export const actions = {

  // eslint-disable-next-line no-unused-vars
  async [GET_ALL_BOOKINGS]({ commit }, { filter }) {
    commit(REQUESTING);
    await meetingRoomBookingService
      .getAllBookings(filter)
      .then(
        (result) => {
          commit(ALL_BOOKING_SUCCESS, { data: result })
        },
        (error) => {
          let message = sharedHelper.getErrorMessage(error);
          sharedHelper.alertErrorMessage(message, error.response.data.code)
          commit(FAILURE);
        }
      )
  },

  // eslint-disable-next-line no-unused-vars
  async [GET_BOOKING]({ commit }, { id }) {
    await meetingRoomBookingService
      .getBooking(id)
      .then(
        (result) => {
          commit(GET_BOOKING_SUCCESS, result)
        },
        (error) => {
          let message = sharedHelper.getErrorMessage(error);
          sharedHelper.alertErrorMessage(message, error.response.data.code)
          commit(FAILURE);
        }
      );
  },

  [DELETE_BOOKING]({ commit }, { id }) {
    meetingRoomBookingService
      .deleteBooking(id)
      .then(
        // eslint-disable-next-line no-unused-vars
        (result) => {
          var message = i18n.t("message.deleteXSuccess", [i18n.t('module.booking')]);
          sharedHelper.alertSuccessMessage(message)
          commit(SUCCESS);
        },
        (error) => {
          let message = sharedHelper.getErrorMessage(error);
          sharedHelper.alertErrorMessage(message, error.response.data.code)
          commit(FAILURE);
        }
      );
  },

  // eslint-disable-next-line no-unused-vars
  [UPDATE_BOOKING]({ commit }, { data }) {
    const startTime = '08:00'; // Format: 'HH:mm'
    const endTime = '18:00';
    const startTimeObj = new Date(`${data['date']}T${startTime}`);
    const endTimeObj = new Date(`${data['date']}T${endTime}`);
    const startTimeToCheckObj = new Date(`${data['date']}T${data['start'].slice(0, 5)}`);
    const endTimeToCheckObj = new Date(`${data['date']}T${data['end'].slice(0, 5)}`);

    if ((startTimeToCheckObj >= startTimeObj && startTimeToCheckObj <= endTimeObj) || (endTimeToCheckObj >= startTimeObj && endTimeToCheckObj <= endTimeObj)) {
      commit(REQUESTING);
    meetingRoomBookingService
      .updateBooking(data)
      .then(
        // eslint-disable-next-line no-unused-vars
        (result) => {
          var message = i18n.t("message.updateXSuccess", [i18n.t('module.booking')]);
          sharedHelper.alertSuccessMessage(message)
          commit(SUCCESS);
        },
        (error) => {
          let message = sharedHelper.getErrorMessage(error);
          sharedHelper.alertErrorMessage(message, error.response.data.code)
          commit(FAILURE);
        }
      );
    } else {
      sharedHelper.alertErrorMessage("Invalid Time Range", "1.24.009")
      commit(FAILURE);
    }
    
   
  },

  // eslint-disable-next-line no-unused-vars
  [CREATE_BOOKING]({ commit }, { data }) {
    const startTime = '08:00'; // Format: 'HH:mm'
    const endTime = '18:00';
    const startTimeObj = new Date(`${data['date']}T${startTime}`);
    const endTimeObj = new Date(`${data['date']}T${endTime}`);
    const startTimeToCheckObj = new Date(`${data['date']}T${data['start'].slice(0, 5)}`);
    const endTimeToCheckObj = new Date(`${data['date']}T${data['end'].slice(0, 5)}`);

    if ((startTimeToCheckObj >= startTimeObj && startTimeToCheckObj <= endTimeObj) || (endTimeToCheckObj >= startTimeObj && endTimeToCheckObj <= endTimeObj)) {
      commit(REQUESTING);
      meetingRoomBookingService
        .createBooking(data)
        .then(
          // eslint-disable-next-line no-unused-vars
          (result) => {
            var message = i18n.t("message.createXSuccess", [i18n.t('module.booking')]);
            sharedHelper.alertSuccessMessage(message)
            commit(SUCCESS);
          },
          (error) => {
            let message = sharedHelper.getErrorMessage(error);
            sharedHelper.alertErrorMessage(message, error.response.data.code)
            commit(FAILURE);
          }
        )
    } else {
      sharedHelper.alertErrorMessage("Invalid Time Range", "1.24.009")
      commit(FAILURE);
    }
  },

};

export const mutations = {
  [ALL_BOOKING_SUCCESS](state, { data }) {
    let d = data.data
    state.status = {};

    state.list.data = []
    if (data.success) {
      let list = []
      d.forEach((x) => {
        var className = 'bg-primary text-white';
        switch (x.meeting_room.color_id) {
          case 2:
          case "2":
            className = "bg-success text-white"
            break;
          case 3:
          case "3":
            className = "bg-info text-white"
            break;
        }

        list.push({
          id: x.id,
          meetingRoomId: x.meeting_room_id,
          title: x.agenda,
          data_date: moment(x.start).format('YYYY-MM-DD'),
          data_start: moment(x.start).format('hh:mm a'),
          data_end: moment(x.end).format('hh:mm a'),
          start: x.start,
          end: x.end,
          className: className,
          type: x.type,
          detail: x.detail,
          entryBY: x.entry_by,
        })
      })

      state.list.data = list
    }
  },
  [SUCCESS](state) {
    state.status = {};
    // router.push({ name: "Calendar" });
    location.reload();
  },
  [FAILURE](state) {
    state.status = {};
  },
  [REQUESTING](state) {
    state.status = { processiong: true };
  },
  [GET_BOOKING_SUCCESS](state, data) {
    let x = data.data

    state.details = {
      meetingRoomId: x.meeting_room_id,
      agenda: x.agenda,
      data_date: moment(x.start).format('YYYY-MM-DD'),
      data_start: moment(x.start).format('hh:mm a'),
      data_end: moment(x.end).format('hh:mm a'),
      start: x.start,
      end: x.end,
      type: x.type,
      detail: x.detail,
      entryBY: x.entry_by,
      user: x.booking_by != null ? x.booking_by.user : null,
      internal_user: x.booking_by_internal_user != null ? x.booking_by_internal_user[0].user : null,
    }
  },
}